//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//1 import axios from "axios";
//1 import api from "../api.js";
export default {
  data: () => ({
    allEnabled: true, //1 false
    menuItems: [
      { icon: "mdi-information-outline", title: "About", action: "about" },
      { icon: "mdi-exit-to-app", title: "LogOut", action: "logout" },
    ],
  }),
  created() {
    //2 this.menuItems[0].title = "Ver: " + this.version;
  },
  mounted() {
    /*1
    if (!this.isShowed || !this.IsUpdated) {
      this.isShowed = true;
      axios
        .post(api.URL() + "/api/v1/checkversion", {
          token: sessionStorage.getItem("Token"),
          version: this.version,
        })
        .then((response) => {
          this.IsUpdated = response.data;
          this.allEnabled = this.IsUpdated;
        });
    }
    1*/
  },
  methods: {
    /*1
    getWebVersion() {
      return this.version;
    },
    2*/
    getEnabled(method) {
      return (
        this.allEnabled &&
        (parseInt(sessionStorage.getItem("UserId")) < 110 || method == "")
      );
    },
    goBack() {
      sessionStorage.setItem("Token", "");
      this.$router.push("/");
    },
    goMenu(action) {
      switch (action) {
        case "about":
          this.goAbout();
          break;
        case "logout":
          this.goLogOut();
          break;
        default:
      }
    },
    goTasques() {
      this.$router.push("/workcalendarlist?init=true");
    },
    goLlistes() {
      this.$router.push("/listitems00list");
    },
    goGArticlesLogisticUpdates() {
      this.$router.push("/logisticupdates");
    },
    goTimeControl() {
      this.$router.push("/timecontrol");
    },
    goAppUpdate() {
      sessionStorage.setItem("Token", "");
      this.$router.push("/");
      window.location.reload(true);
    },
    goWorkDaySummary() {
      this.$router.push("/workdaysummary?init=true");
    },
    goAbout() {
      this.$router.push("/about");
    },
    goLogOut() {
      //this.$router.push("/googlemaps");
      sessionStorage.setItem("Token", "");
      this.$router.push("/");
    },
  },
};
